<template>
  <div>
       <div>
         <el-row :gutter="20">
           <el-col :span="24">
             <div class="tool">
               <div>
                  <label class="title">上报时间：</label>
                  <el-date-picker v-model="searchForm.upload_time" type="daterange" range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>
                <div class="a">
                  <label class="title">维修时间：</label>
                  <el-date-picker v-model="searchForm.repair_time" type="daterange" range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>
                <el-input v-model="searchForm.name" placeholder="点位名称或故障单号" class="a" style="width:200px;"></el-input>
             </div>
           </el-col>
         </el-row>
          <el-row :gutter="20" style="margin-top:20px">
           <el-col :span="24">
             <div class="tool">
                <div class="tool a">
                  <label class="title" style="width:90px">维修人员：</label>
                  <el-select v-model="searchForm.repair_user_id" style="width:100%;">
                      <el-option label="全部" :value='0'></el-option>
                      <el-option v-for="item in UserDataList" :key="item.user_id" :label="item.user_name" :value='item.user_id'></el-option>
                  </el-select>
                </div>
                <div class="a">
                  <label class="title">状态：</label>
                  <el-select v-model="searchForm.status" placeholder="请选择">
                      <el-option label="全部" :value="0"></el-option>
                      <el-option label="维修成功" :value="1"></el-option>
                      <el-option label="维修失败" :value="2"></el-option>
                  </el-select>
                </div>
                <el-button type="primary" icon="el-icon-search" class="a" @click="Search"></el-button>
             </div>
           </el-col>
         </el-row>
        </div>

        <el-table :data='DataList' stripe :highlight-current-row='true'  @expand-change="expandChanged"  max-height='calc(100vh - 205px)' height='calc(100vh - 205px)'>
             <el-table-column type="expand">
                 <template slot-scope="slot">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <div>
                            <el-image style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  v-for='(item,index) in slot.row.faultPicData' :key="index" :src='item.photo' fit="fill" @click="ImgClick(slot.row,1,index)"></el-image>
                            <span style="margin-left:30px;font-size:15px">故障描述：{{slot.row.order_detail}}</span>
                            <el-dialog title="故障详细图" :visible.sync="imgDialogVisible" :append-to-body='true'>
                              <img width="100%" :src="imgSrc" alt="">
                            </el-dialog>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="tool">
                            <el-image style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  v-for='(item,index) in slot.row.repairPicData' :key="index" :src='item.photo' fit="fill" @click="ImgClick(slot.row,2,index)"></el-image>
                            <div>
                              <div style="margin-left:30px;font-size:15px">故障分析：{{slot.row.order_analysis}}</div>
                              <div style="margin-left:30px;font-size:15px;margin-top:10px">维修方案：{{slot.row.order_repair_method}}</div>
                            </div>
                            <el-dialog title="故障详细图" :visible.sync="imgDialogVisible" :append-to-body='true'>
                              <img width="100%" :src="imgSrc" alt="">
                            </el-dialog>
                        </div>
                      </el-col>
                    </el-row>
                 </template>
             </el-table-column>
            <el-table-column align="center" prop="order_no" label="故障单号"></el-table-column>
            <el-table-column align="center" prop="order_time" label="上报时间">
                <template slot-scope="slot">
                    {{FormateData(slot.row.order_time,2)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="media_name" label="点位名称"></el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="地址"></el-table-column>
            <el-table-column align="center" prop="order_report_user" label="上报人员"></el-table-column>
            <el-table-column align="center" prop="order_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="order_detail" label="故障描述"></el-table-column>
            <el-table-column align="center" prop="order_repair_time" label="维修时间">
                <template slot-scope="slot">
                    {{FormateData(slot.row.order_repair_time,2)}}
                </template>
            </el-table-column>

            <el-table-column align="center" prop="user_name" label="维修人员"></el-table-column>
            <el-table-column align="center" prop="order_status" label="状态">
                <template slot-scope="slot">
                    {{slot.row.order_status===0?'成功':'失败'}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      DataList: [],
      UserDataList: [],
      searchForm: {
        upload_time: [],
        repair_time: [],
        status: 0,
        name: '',
        repair_user_id: 0,
        pageNo: 1,
        pageSize: 18,
        total: 0
      },
      imgDialogVisible: false,
      imgSrc: ''
    }
  },
  computed: {
    FormateData () {
      return function (time, type) {
        return util.FormateDate(time, type)
      }
    }
  },
  created () {
    util.Get('user/getuserdatalist').then(res => {
      if (res.rpStatus === 10006) {
        this.UserDataList = res.list
      }
    })
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Get('repairorder/getrepairorderdatalist', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(element => {
              element.faultPicData = ''
              element.repairPicData = ''
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search () {
      this.GetDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    expandChanged (row, expandedRows) {
      var arr = expandedRows.filter(a => a.order_id === row.order_id)
      if (arr.length > 0) {
        // 展开行
        if ((row.faultPicData === '' && row.fault_photo !== null && row.fault_photo !== undefined && row.fault_photo !== '') || (row.repairPicData === '' && row.order_photo !== null && row.order_photo !== undefined && row.order_photo !== '')) {
          util.Get('picture/getpicture', { state_type1: 4, pic_name1: row.order_photo, state_type2: 3, pic_name2: row.fault_photo }).then(res => {
            if (res.rpStatus === 10000) {
              row.repairPicData = res.list[0]
              row.faultPicData = res.list[1]
            }
          })
        }
      }
    },
    ImgClick (row, photoType, index) {
      this.imgSrc = ''
      this.imgDialogVisible = true
      if (photoType === 1) {
        if (row.faultPicData !== null && row.faultPicData.length > 0) { this.imgSrc = row.faultPicData[index].photo }
      } else {
        if (row.repairPicData !== null && row.repairPicData.length > 0) { this.imgSrc = row.repairPicData[index].photo }
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}

.title{
    font-size: 13px;

}
.a{
    margin-left: 20px;
}
.el-table{
    margin-top: 15px;
}
.el-link{
    margin-right: 10px;
    font-size: 10px;
}
</style>
